"use client"
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';

const ConfigContext = createContext();

export const ConfigProvider = ({ children, configData }) => {

    const [configDataLocal, setConfigDataLocal] = useState({});

    const verifyConfigData = async () => {
        if (!configData) {
            console.error('Config data not found, fetching from API');
            let responseConfig = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/config/unauth`, {
                headers: {
                    'Referer': process.env.NEXT_PUBLIC_FRONTEND_URL
                }
            });

            let tempConfigData = await responseConfig.json();
            setConfigDataLocal(JSON.parse(tempConfigData.config));
        }
    }

    useEffect(() => {
        verifyConfigData();
    }, []);

    const configValue = useMemo(() => {
        return Object.keys(configDataLocal ?? {}).length > 0
            ? configDataLocal
            : (Object.keys(configData ?? {}).length > 0 ? configData : {});
    }, [configDataLocal, configData]);

    return (
        <ConfigContext.Provider value={configValue}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => {
    return useContext(ConfigContext);
};